import React from 'react';
import TextToggle from '../components/text-toggle/text-toggle';
import WoodHome from '../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import TopBunerProduct from '../components/top-buner/top-baner-product';
import OfficeAtMap from '../components/office-on-map/office-on-map';
import ImagesView from '../components/images-view/images-view';
import Advantages from '../components/advantages/advantages';
import FormQuickOrder from '../components/form-quick-oreder/form-quick-order';
import PriceList from '../components/price-list/price-list';


const PokraskaDomaIzSruba = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Реставрация деревянных домов', url:'#', status:true}]
  return (
    <div>
        <SEO title="Реставрация деревянных домов в Калининградской области"
            description="Опытные бригады в срок выполнят работы по реставрации деревянного дома или сруба в Калининградской области.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Реставрация деревян&shy;ных домов в Кали&shy;нинград&shy;ской области'} 
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1 className="text-center text-md-left">Реставрация деревянных домов</h1>
                <p>
                В целом капремонт деревянных строений предполагает то же, что и всех остальных. 
                Это усиление фундамента, несущих конструкций, ремонт (замена) кровли, 
                ремонт стен снаружи и внутри, замена сантехники и так далее. 
                Особенность ремонта деревянных построек – трудоемкость процесса и недюжинная 
                физическая сила, которая потребуется при замене прогнивших бревен.
                </p>
                  <p>
                  Избежать замены пришедшего в негодность дерева никак нельзя. 
                  Если, например, оставить в основании дома гнилые бревна, 
                  то вскоре «завалится» вся конструкция, так как нагрузка 
                  распределится неравномерно. А целые и, следовательно, 
                  более тяжелые бревна попросту раздавят утратившие прочность 
                  нижние части строения. Кроме того, процессы гниения весьма 
                  заразны и быстро распространяются на пока еще целые участки 
                  дерева.
                  </p>
                  <p>
                  Аналогичным процессам подвержен  дом из бруса. Траченный временем брус тоже 
                  требует замены. Есть исключение – это дома из клееного материала, 
                  стойкого к износу.  Трудоемкость процесса относительно работы с другими 
                  материалами делает очевидным выбор в пользу обращения к профессионалам.
                  </p>
                
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="">
          <Advantages />
         </Container>
        <FormQuickOrder className="mt-5" />
        <ImagesView />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default PokraskaDomaIzSruba;